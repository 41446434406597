@keyframes pulse {
  0% {
    backdrop-filter: blur(10px);
  }
  50% {
    backdrop-filter: blur(50px);
  }
  100% {
    backdrop-filter: blur(30px);
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
.grid-item-entering,
.grid-item-leaving,
.grid-item-leaving-active {
  transition: all 300ms ease-in-out;
}

.grid-item-entering {
  opacity: 0;
  transform: scale(0.8);
}

.grid-item-leaving-active {
  opacity: 0;
  transform: scale(0.8);
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

.parent-div .zoom-icon {
  opacity: 0 !important;
  transition: opacity 1s ease, transform 0.3s ease;
  transform: translate(4%, -0%) scale(0.2);
}

.parent-div:hover .zoom-icon {
  opacity: 0.2 !important;
  transition: opacity 1s ease, transform 0.3s ease;
  transform: translate(-2%, -7%) scale(0.4);
}

.typing-text {
  overflow: hidden;
  border-right: 0.15em solid white;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  animation-fill-mode: backwards; /* Hide text before animation starts */
}
.typing-text-3 {
  overflow: hidden;
  border-right: 0.15em solid white;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  animation-delay: 3s; /* Delay of 2 seconds */
  animation-fill-mode: backwards; /* Hide text before animation starts */
}
@keyframes flash {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.flashlight {
  animation: flash 0.1s ease-in-out infinite alternate;
}

.flashbulb {
  animation: flashbulb 0.1s ease-in-out 1;
  position: absolute;
  background: radial-gradient(circle, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* border-radius: 50%; */
  box-shadow: 0 0 200px 100px rgba(255, 255, 255, 1);
}

@keyframes flashbulb {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

.picture-transition {
  transition: opacity 0.3s ease-in-out;
}
.yarl__button {
  font-size: 1.5rem !important;
}

.yarl__button .iconify {
  width: 2rem !important;
  height: 2rem !important;
}
.MuiTooltip-popper div {
  font-size: 1em;
  line-height: 1.5em;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
}

.MuiTooltip-popper div {
  max-width: 500px;
}

@-webkit-keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* .smooth {
  position:relative;
  height:281px;
  width:450px;
  margin:0 auto;
}
.smooth {
  position:absolute;
  left:0;
} */
.fade-in {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.text-container {
  display: inline-flex;
  align-items: center;
  color: #fff;
  letter-spacing: 0.3px;
}

.glowing-text {
  animation: glowAndFade 30s infinite alternate;
  display: inline-block;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 0px #ccc, 0 0 5px #ccc, 0 0 0px #b6b6b6, 0 0 20px #a8a8a8, 0 0 25px #919191, 0 0 30px #7d7d7d, 0 0 35px #616161;
  }
  100% {
    text-shadow: none;
  }
}
@keyframes glowAndFade {
  0%,
  100% {
    text-shadow: none;
  }
  2% {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #eee;
    text-color: #fff, #fff, #eee;
  }
  5% {
    text-shadow: none;
  }
}

.glow-effect:hover {
  animation: svgGlowAndFade 9s;
}
@keyframes svgGlowAndFade {
  0%,
  100% {
    filter: drop-shadow(0 0 0 transparent);
  }
  3% {
    filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 10px #fff) drop-shadow(0 0 15px #eee);
  }
  9% {
    filter: drop-shadow(0 0 0px #fff) drop-shadow(0 0 0px #fff) drop-shadow(0 0 0px #eee);
  }
}
.loadingText {
  transition: filter 1.3s ease;
}

.loadingText.blur {
  filter: blur(1px);
}
.glowing-spark {
  animation: glow-animation 0.9s infinite alternate;
}

@keyframes glow-animation {
  from {
    text-shadow: 0 0 5px #ffffff, 0 0 10px #ffffff, 0 0 15px #ffffff, 0 0 20px #ffffff, 0 0 25px #ffffff, 0 0 30px #ffffff, 0 0 35px #ffffff;
  }
  to {
    text-shadow: none;
  }
}
.hover-container:hover .hover-target {
  transform: scale(1);
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
}

.hover-target {
  border-image-width: 0 !important;
  border: 0px solid transparent !important;
}

.glassEffect {
  background: rgba(255, 255, 255, 0.3); /* White background with 30% opacity */
  backdrop-filter: blur(10px); /* Blur the content behind the element */
  border-radius: 10px; /* Optional: Rounded corners */
}

.offScreen {
  margin-left: -100%;
}

.onScreen {
  margin-left: 0;
}
#lightboxContainer,
#lightboxContainer:focus {
  overflow: hidden !important;
  outline: none !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: auto !important;
  position: fixed !important;
}

#slideshowAnim {
  outline: none !important;
}
/* 
  ._2Yv4j {
    top: 13% !important;
  
  } */

@media screen and (min-width: 6em) {
  .yarl__toolbar {
    top: 0% !important;
  }
  ._2Yv4j svg {
    width: 1em !important;
    height: 1em !important;
  }
}

@media screen and (max-width: 1024px) {
  .yarl__toolbar {
    top: 7% !important;
  }
  ._z-bHV {
    max-width: 100% !important;
  }
}
.yarl__slide_image {
  border-radius: 12px;
}

.yarl__fullsize {
  width: auto !important;
}

.reactEasyCrop_CropArea {
  border: none !important;
}

.stripe-buy-button-container stripe-buy-button {
  width: auto !important;
}
.MuiPopover-root {
  z-index: 3000 !important;
}

[data-rmiz-modal-content] {
  background-color: black !important;
}

.react-joyride__overlay {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.__floater__open {
  z-index: 3333 !important;
}
@keyframes scrollText {
  0% {
    transform: translateY(0);
    opacity: 0.7;
  }
  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0.7;
  }
}
